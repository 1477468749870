import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledDomainsBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const styledDomains = css`
  ${styledDomainsBps};
  h5 {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    margin-bottom: 16px;
  }
`

export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const H5Bps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PBps = css(
  mq({
    width: [328, 422, 680, 690],
    textAlign: ['center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['center']
  })
)

export const IdProtectBoxBps = css(
  mq({
    width: [328, 422, 680, 680]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  h5 {
    ${H5Bps};
  }
  p {
    ${PHeaderBps};
  }
  img {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

export const IdProtectBox = css`
  ${IdProtectBoxBps};
  margin: 0 auto !important;
`

export const steptCloudItem = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IdProtectList = css`
  display: flex;
  align-items: end;
  margin-bottom: 16px;
  img {
    margin-right: 16px;
  }
  span {
    font-size: 15.7px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
`
export const faqCloudServer = css`
  background-color: #f3f5f5;
  display: flex;
  justify-content: center;
  h4 {
    color: rgba(0, 0, 0, 0.87);
  }
`

export const legend = css`
  ${PBps};
  font-size: 11.8px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  text-align: left !important;
`

export const domainsList = css`
  text-align: left;
  h6 {
    color: rgba(0, 0, 0, 0.87);
    width: 156px;
  }
  p {
    color: rgba(0, 0, 0, 0.6);
    width: 156px;
  }
`